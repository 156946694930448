export interface ICompanyProduct {
  productId: number;
  locationProductId: number;
  productName: string;
  quantity: string;
  brand: string;
  availability: CompanyProductAvailability;
  cost: number;
}

export enum CompanyProductAvailability {
  Available = 1,
  OutOfStock = 2,
  Unavailable = 3,
  OnlynInShops = 4,
  AvailableOnRequest = 5,
  Preorder = 6
}
