import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { Column, ColumnDef, ColumnFiltersState, PaginationState, SortingState } from '@tanstack/react-table';
import Common from '../../shared/Common';
import { SortUpIcon, SortDownIcon } from '../../shared/icons/Svgs';
import { useNavigate } from 'react-router-dom';
import { CompanyProductAvailability } from '../../../interfaces/ICompanyProduct';
import type { ICompanyProduct } from '../../../interfaces/ICompanyProduct';
import debounce from 'lodash.debounce';
import { Col, Form, Pagination, Row } from 'react-bootstrap';
import { CurrentPriceView, type IPricesView } from '../../../interfaces/IPricesView';

interface IPaginationProps {
  pageIndex: number;
  pageSize: number;
  sortBy: SortingState;
  filters: ColumnFiltersState;
  showOutOfStockProducts: boolean;
  showUnavailableProducts: boolean;
}

interface ICompanyProductsTableProps {
  pricesViewParams: IPricesView;
}

function CompanyProductsTable(props: ICompanyProductsTableProps) {
  const [loading, setLoading] = useState(false);
  const [companyNotFound, setCompanyNotFound] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [products, setProducts] = useState<ICompanyProduct[]>([]);
  const [currentLoadedCompany, setCurrentLoadedCompany] = useState<string | undefined>(undefined);
  const [currentLoadedRequestUrl, setCurrentLoadedRequestUrl] = useState<string | undefined>(undefined);
  const [pageCount, setPageCount] = useState(0);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [showOutOfStockProducts, setShowOutOfStockProducts] = useState(true);
  const [showUnavailableProducts, setShowUnavailableProducts] = useState(false);
  const fetchIdRef = useRef(0);
  const navigate = useNavigate();

  const showQuantityColumn =
    props.pricesViewParams.selectedCompany !== 'Hale Banacha' &&
    props.pricesViewParams.selectedCompany !== 'Delio' &&
    props.pricesViewParams.selectedCompany !== 'Selgros';

  useEffect(() => {
    if (props.pricesViewParams.selectedCompany) {
      // gdy zmieniamy sklep, to ustawiamy paginację na 0
      if (props.pricesViewParams.selectedCompany !== currentLoadedCompany) {
        setPagination({
          pageIndex: 0,
          pageSize: 10,
        });
      }
    }
  }, [props.pricesViewParams.selectedCompany, currentLoadedCompany]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const fetchData = useCallback(
    async ({
      pageIndex,
      pageSize,
      sortBy,
      filters,
      showOutOfStockProducts,
      showUnavailableProducts,
    }: IPaginationProps) => {
      if (currentLoadedCompany !== props.pricesViewParams.selectedCompany && pageIndex > 0) {
        // w przypadku, gdy zmienimy sklep na inny i była ustawiona paginacja, to
        // nie chcemy wyszukiwać danych z wcześniej ustawioną paginacją. Próba taka
        // będzie miała miejsce ze względu na tablicę zależności useEffect
        // wykorzystującą tę funkcję
        return;
      }
      const filterRequest = filters.map((f) => '&' + f.id + 'Filter=' + f.value).join('');
      var sortByRequest = '';
      if (sortBy.length > 0) {
        sortByRequest = sortBy.map((s) => '&sortBy=' + s.id + (s.desc ? ' DESC' : ' ASC')).join('');
      } else {
        sortByRequest = '&sortBy=productId ASC';
      }
      const requestUrl =
        'api/prices/getCompanyProducts?companyName=' +
        props.pricesViewParams.selectedCompany +
        '&locationId=' +
        props.pricesViewParams.selectedLocationId +
        '&pageIndex=' +
        pageIndex +
        '&pageSize=' +
        pageSize +
        '&showOutOfStockProducts=' +
        showOutOfStockProducts +
        '&showUnavailableProducts=' +
        showUnavailableProducts +
        filterRequest +
        sortByRequest;
      if (requestUrl === currentLoadedRequestUrl) {
        // nie ładujemy ponownie tych samych danych
        return;
      }
      const fetchId = ++fetchIdRef.current;
      setCompanyNotFound(false);
      setLoading(true);
      const response = await Common.authorizedFetch(requestUrl, undefined, true);
      const data = await response.json();
      if (data.success) {
        if (fetchId === fetchIdRef.current) {
          if ((data.result.products?.length ?? 0) > 0) {
            setProducts(data.result.products);
            setPageCount(Math.ceil(data.result.products[0].totalCount / pageSize));
          } else {
            setProducts([]);
            setPageCount(0);
          }
        }
      } else {
        setCompanyNotFound(true);
        setProducts([]);
        setPageCount(0);
      }
      setLoading(false);
      setCurrentLoadedCompany(props.pricesViewParams.selectedCompany);
      setCurrentLoadedRequestUrl(requestUrl);
    },
    [
      currentLoadedCompany,
      currentLoadedRequestUrl,
      props.pricesViewParams.selectedCompany,
      props.pricesViewParams.selectedLocationId,
    ]
  );

  const columns = useMemo<ColumnDef<ICompanyProduct>[]>(() => {
    const columns: ColumnDef<ICompanyProduct>[] = [];
    columns.push({
      accessorFn: (row) => row.productName,
      id: 'productName',
      header: () => <span>Produkt</span>,
      cell: (info) => <span>{info.row.original.productName}</span>,
    });
    if (showQuantityColumn) {
      columns.push({
        accessorFn: (row) => row.quantity,
        id: 'quantity',
        header: () => <span>Ilość</span>,
        cell: (info) => (
          <span>{info.row.original.quantity === '0' ? '' : info.row.original.quantity?.toLowerCase()}</span>
        ),
        enableColumnFilter: false,
      });
    }
    columns.push({
      accessorFn: (row) => row.brand,
      id: 'brand',
      header: () => <span>Marka</span>,
      cell: (info) => <span>{info.row.original.brand}</span>,
    });
    columns.push({
      accessorFn: (row) => row.cost,
      id: 'cost',
      header: () => <span>Cena</span>,
      cell: (info) => <span>{Common.Utils.getCurrencyString(info.row.original.cost)}</span>,
      enableColumnFilter: false,
    });
    columns.push({
      accessorFn: (row) => row.availability,
      id: 'availability',
      header: () => <span>Dostępność</span>,
      cell: (info) => (
        <span>
          {((availability: CompanyProductAvailability) => {
            switch (availability) {
              case CompanyProductAvailability.Available:
                return 'Dostępny';
              case CompanyProductAvailability.Unavailable:
                return 'Usunięty';
              case CompanyProductAvailability.OutOfStock:
                return 'Brak zapasów';
              case CompanyProductAvailability.OnlynInShops:
                return 'Tylko w sklepach';
              case CompanyProductAvailability.AvailableOnRequest:
                return 'Na zamówienie';
              case CompanyProductAvailability.Preorder:
                return 'Preorder';
            }
          })(info.row.original.availability)}
        </span>
      ),
      enableSorting: false,
      enableColumnFilter: false,
    });
    return columns;
  }, [showQuantityColumn]);

  const table = useReactTable({
    data: products,
    columns: columns,
    pageCount: pageCount,
    state: {
      columnFilters,
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
  });

  const debouncedFilterSearchChanged = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>, column: Column<ICompanyProduct, unknown>) => {
        column.setFilterValue(e.target.value);
        table.setPageIndex(0);
      }, 500),
    [table]
  );

  useEffect(() => {
    if (props.pricesViewParams.currentView !== CurrentPriceView.CompanyProducts) {
      return;
    }
    fetchData({
      pageIndex,
      pageSize,
      sortBy: sorting,
      filters: columnFilters,
      showOutOfStockProducts,
      showUnavailableProducts,
    });
  }, [
    fetchData,
    pageIndex,
    pageSize,
    sorting,
    columnFilters,
    showOutOfStockProducts,
    showUnavailableProducts,
    props.pricesViewParams.currentView,
  ]);

  let items = [];
  for (
    let ind = 0,
      number = pageIndex < 2 ? 0 : pageIndex + 2 >= table.getPageCount() ? table.getPageCount() - 5 : pageIndex - 2;
    ind < 5;
    number++
  ) {
    if (number + 1 > table.getPageCount()) {
      break;
    }
    if (number + 1 <= 0) {
      continue;
    }
    items.push(
      <Pagination.Item key={number} active={number === pageIndex} onClick={() => table.setPageIndex(number)}>
        {number + 1}
      </Pagination.Item>
    );
    ind++;
  }
  return (
    <>
      {companyNotFound && <h1 className='text-danger'>Nie znaleziono sklepu</h1>}
      <Pagination>
        <Pagination.First onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} />
        <Pagination.Prev onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />
        {items}
        <Pagination.Next onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
        <Pagination.Last
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        />
      </Pagination>
      <div className='table-responsive'>
        <table id='company-product-table' className='table table-hover'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <>
                        {header.column.getCanFilter() ? (
                          <div>
                            <input
                              onChange={(e) => {
                                debouncedFilterSearchChanged(e, header.column);
                              }}
                              placeholder={'Szukaj...'}
                              className='no-border-input'
                            />
                          </div>
                        ) : null}
                        <div
                          {...{
                            style: header.column.getCanSort() ? { cursor: 'pointer' } : {},
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                            desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {loading
              ? Common.Ui.getSkeletonTableRows(10, showQuantityColumn ? 5 : 4, 35)
              : table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate(
                        '/prices/' +
                          Common.Utils.titleCaseToCamelCase(props.pricesViewParams.selectedCompany ?? '') +
                          '/' +
                          props.pricesViewParams.selectedLocationId +
                          '/' +
                          row.original.locationProductId
                      )
                    }
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <Row className='my-1'>
        <Col xs={12}>
          <Form.Check
            type='switch'
            id='show-out-of-stock-products-switch'
            label='Pokaż aktualnie niedostępne produkty'
            style={{ userSelect: 'none' }}
            checked={showOutOfStockProducts}
            onChange={() => setShowOutOfStockProducts((prev) => !prev)}
          />
        </Col>
        <Col>
          <Form.Check
            type='switch'
            id='show-unavailable-products-switch'
            label='Pokaż usunięte produkty'
            style={{ userSelect: 'none' }}
            checked={showUnavailableProducts}
            onChange={() => setShowUnavailableProducts((prev) => !prev)}
          />
        </Col>
      </Row>
    </>
  );
}

export default CompanyProductsTable;
