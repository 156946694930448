import { useCallback, useEffect, useRef, useState } from 'react';
import type { ICompanyProductDetails } from '../../../interfaces/ICompanyProductDetails';
import Common from '../../shared/Common';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import type { ICompanyProductAvailabilityHistory } from '../../../interfaces/ICompanyProductAvailabilityHistory';
import { Form } from 'react-bootstrap';
import type { ISimilarCompanyProductAvailabilityHistory } from '../../../interfaces/ISimilarCompanyProductAvailabilityHistory';

interface IProductDetailsPriceHistoryProps {
  availabilityHistory: ICompanyProductAvailabilityHistory[];
  selectedProduct: ICompanyProductDetails | undefined;
  resetDataTrigger: number;
}

function ProductDetailsAvailabilityHistory(props: IProductDetailsPriceHistoryProps) {
  const [showSimilarProductsAvialabilities, setShowSimilarProductsAvialabilities] = useState(false);
  const [similarProductsAvailabilityHistory, setSimilarProductsAvailabilityHistory] = useState<
    ISimilarCompanyProductAvailabilityHistory[]
  >([]);
  const [loading, setLoading] = useState(false);
  const fetchIdRef = useRef(0);

  const { availabilityHistory, selectedProduct, resetDataTrigger } = props;

  useEffect(() => {
    setSimilarProductsAvailabilityHistory([]);
    setShowSimilarProductsAvialabilities(false);
  }, [resetDataTrigger]);

  const fetchSimilarProductsAvialabilityHistoryData = useCallback(async () => {
    if (
      similarProductsAvailabilityHistory.length > 0 ||
      !showSimilarProductsAvialabilities ||
      (selectedProduct?.similarProductIds?.length ?? 0) === 0
    ) {
      return;
    }
    setLoading(true);
    const fetchId = ++fetchIdRef.current;
    const response = await Common.authorizedFetch(
      'api/prices/getSimilarProductsAvailabilityHistoryData?similarProductIds=' +
        selectedProduct?.similarProductIds?.join('&similarProductIds=')
    );
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchIdRef.current) {
        if (data.result.similarProductsAvailabilityHistory) {
          setSimilarProductsAvailabilityHistory(
            data.result.similarProductsAvailabilityHistory.map((h: ISimilarCompanyProductAvailabilityHistory) => ({
              companyLocationName: h.companyLocationName,
              productUrl: h.productUrl,
              history: h.history.map((value: any) => ({
                dateValue: new Date(value.date).valueOf(),
                availability: value.availability,
                company: h.companyLocationName,
              })),
            }))
          );
        }
      }
    }
    setLoading(false);
  }, [
    similarProductsAvailabilityHistory,
    showSimilarProductsAvialabilities,
    selectedProduct?.similarProductIds,
    setLoading,
  ]);

  const generateAvailabilityHistoryScattersChartContent = useCallback(() => {
    const getScatterFillColor = (company: string) => {
      if (company === 'Auchan') {
        return '#00904B';
      }
      if (company === 'Carrefour') {
        return '#33348E';
      }
      if (company === 'Frisco') {
        return '#A5004E';
      }
      if (company === 'Lisek') {
        return '#E88601';
      }
      if (company === 'Hale Banacha') {
        return '#005CA2';
      }
      if (company === 'Polski Koszyk') {
        return '#000000';
      }
      if (company === 'Delio') {
        return '#004946';
      }
      if (company === 'Selgros') {
        return '#E20026';
      }
      return '#000000';
    };

    const scatters = [];
    scatters.push(
      <Scatter
        key={0}
        name={selectedProduct?.companyName}
        data={availabilityHistory}
        fill={getScatterFillColor(selectedProduct?.companyName ?? '')}
        cursor='pointer'
        onClick={(_) => {
          const productUrl = selectedProduct?.url;
          if (productUrl) {
            window.open(productUrl, '_blank')?.focus();
          }
        }}
      />
    );

    if (showSimilarProductsAvialabilities) {
      for (var ind = 0; ind < similarProductsAvailabilityHistory.length; ind++) {
        scatters.push(
          <Scatter
            key={ind + 1}
            name={similarProductsAvailabilityHistory[ind].companyLocationName}
            data={similarProductsAvailabilityHistory[ind].history}
            fill={getScatterFillColor(similarProductsAvailabilityHistory[ind].companyLocationName ?? '')}
            cursor='pointer'
            onClick={(e: any) => {
              const company = e.company ?? '';
              var product = similarProductsAvailabilityHistory.find((p) => p.companyLocationName === company);
              const productUrl = product?.productUrl;
              if (productUrl) {
                window.open(productUrl, '_blank')?.focus();
              }
            }}
          />
        );
      }
    }

    return scatters;
  }, [
    availabilityHistory,
    similarProductsAvailabilityHistory,
    selectedProduct?.companyName,
    showSimilarProductsAvialabilities,
    selectedProduct?.url,
  ]);

  useEffect(() => {
    fetchSimilarProductsAvialabilityHistoryData();
  }, [fetchSimilarProductsAvialabilityHistoryData]);

  return (
    <>
      {Common.Ui.showLoadingSpinnerAbsolute(loading)}
      {(selectedProduct?.similarProductIds?.length ?? 0) > 0 && (
        <Form.Check
          className='my-2'
          type='switch'
          id='show-similar-products-availabilities-switch'
          label='Porównaj dostępność produktu w innych sklepach'
          style={{ userSelect: 'none' }}
          checked={showSimilarProductsAvialabilities}
          onChange={() => setShowSimilarProductsAvialabilities((prev) => !prev)}
        />
      )}
      <div className='overflow-auto'>
        <ResponsiveContainer minWidth={600} width='100%' height={400}>
          <ScatterChart
            margin={{
              left: 5,
              right: 5,
              top: 5,
              bottom: 5,
            }}
          >
            {generateAvailabilityHistoryScattersChartContent()}
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='dateValue'
              name='Data'
              type='number'
              domain={['dataMin', 'dataMax']}
              tickFormatter={(value) => new Date(value).toLocaleDateString()}
            />
            <YAxis
              type='number'
              dataKey='availability'
              name='Dostępność'
              tickFormatter={(_) => ''}
              reversed={true}
              ticks={[1, 2, 3]}
              domain={[1, 3]}
              interval={0}
            />
            <ZAxis type='category' dataKey='company' name='Sklep' />
            <Legend
              verticalAlign='top'
              iconSize={10}
              height={36}
              onClick={(e: any) => {
                const company = e.value ?? '';
                var productUrl: string | undefined = undefined;
                if (e.value === selectedProduct?.companyName) {
                  productUrl = selectedProduct?.url;
                } else {
                  var product = similarProductsAvailabilityHistory.find((p) => p.companyLocationName === company);
                  productUrl = product?.productUrl;
                }
                if (productUrl) {
                  window.open(productUrl, '_blank');
                }
              }}
            />
            <Tooltip
              formatter={(value, name, _) => {
                if (name === 'Data') {
                  return new Date(value as number).toLocaleString();
                }
                if (name === 'Dostępność') {
                  if (value === 1) {
                    return 'Dostępny';
                  }
                  if (value === 2) {
                    return 'Brak zapasów';
                  }
                  if (value === 3) {
                    return 'Usunięty';
                  }
                  if (value === 4) {
                    return 'Tylko w sklepach';
                  }
                  if (value === 5) {
                    return 'Na zamówienie';
                  }
                  if (value === 6) {
                    return 'Preorder';
                  }
                }
                return value;
              }}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

export default ProductDetailsAvailabilityHistory;
