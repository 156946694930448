import {
  type ColumnDef,
  type SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { IScraper } from '../../../../interfaces/IScraper';
import { useState, useEffect, useMemo } from 'react';
import Common from '../../../shared/Common';
import { SortUpIcon, SortDownIcon } from '../../../shared/icons/Svgs';
import { Form } from 'react-bootstrap';

export interface ICompanyScrapersSummaryTableProps {
  data: IScraper[];
  loading: boolean;
  summaryScraperRow: IScraper;
  groupByCompany: boolean;
  setGroupByCompany: React.Dispatch<React.SetStateAction<boolean>>;
  fetchData: () => Promise<void>;
}

const CompanyScrapersSummaryTable = (props: ICompanyScrapersSummaryTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = useMemo<ColumnDef<IScraper>[]>(
    () => [
      {
        accessorFn: (row) => row.name,
        id: 'name',
        header: () => (props.groupByCompany ? <span>Firma</span> : <span>Lokalizacja</span>),
        footer: () => <span>{props.summaryScraperRow.name}</span>,
      },
      {
        accessorFn: (row) => row.productCount,
        id: 'productCount',
        header: () => <span>Produkty</span>,
        cell: (info) => <span>{Common.Utils.getNumberWithSpaces(info.row.original.productCount)}</span>,
        footer: () => <span>{Common.Utils.getNumberWithSpaces(props.summaryScraperRow.productCount)}</span>,
      },
      {
        accessorFn: (row) => row.categoryCount,
        id: 'categoryCount',
        header: () => <span>Kategorie</span>,
        cell: (info) => <span>{Common.Utils.getNumberWithSpaces(info.row.original.categoryCount)}</span>,
        footer: () => <span>{Common.Utils.getNumberWithSpaces(props.summaryScraperRow.categoryCount)}</span>,
      },
      {
        accessorFn: (row) => row.feedCount,
        id: 'feedCount',
        header: () => <span>Zasilenia</span>,
        cell: (info) => <span>{Common.Utils.getNumberWithSpaces(info.row.original.feedCount)}</span>,
        footer: () => <span>{Common.Utils.getNumberWithSpaces(props.summaryScraperRow.feedCount)}</span>,
      },
      {
        accessorFn: (row) => row.collectedRecordsCount,
        id: 'collectedRecordsCount',
        header: () => (
          <span>
            Przetworzone
            <br />
            produkty
          </span>
        ),
        cell: (info) => <span>{Common.Utils.getNumberWithSpaces(info.row.original.collectedRecordsCount)}</span>,
        footer: () => <span>{Common.Utils.getNumberWithSpaces(props.summaryScraperRow.collectedRecordsCount)}</span>,
      },
      {
        accessorFn: (row) => row.averageProcessingTime,
        id: 'averageProcessingTime',
        header: () => (
          <span>
            Średni czas
            <br />
            przetwarzania
          </span>
        ),
        cell: (info) => (
          <span>{Common.Utils.getComponentTimestampFromSeconds(info.row.original.averageProcessingTime)}</span>
        ),
        footer: () => (
          <span>{Common.Utils.getComponentTimestampFromSeconds(props.summaryScraperRow.averageProcessingTime)}</span>
        ),
      },
      {
        accessorFn: (row) => row.lastCompletionDate,
        id: 'lastCompletionDate',
        header: () => <span>Ostatnie zasilenie</span>,
        cell: (info) => (
          <span>{Common.Utils.getDateWithTimeWithoutZoneConverter(info.row.original.lastCompletionDate)}</span>
        ),
        footer: () => (
          <span>{Common.Utils.getDateWithTimeWithoutZoneConverter(props.summaryScraperRow.lastCompletionDate)}</span>
        ),
      },
    ],
    [props.summaryScraperRow, props.groupByCompany]
  );

  const table = useReactTable({
    data: props.data,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { fetchData } = props;

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Form.Check
        className='my-2'
        type='switch'
        id='group-by-company-switch'
        label='Pokaż zgrupowane dane po firmie'
        style={{ userSelect: 'none' }}
        checked={props.groupByCompany}
        onChange={() => {
          props.setGroupByCompany((prev) => !prev);
        }}
      />
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          style: header.column.getCanSort() ? { cursor: 'pointer' } : {},
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <SortUpIcon style={{ marginLeft: '5px' }} />,
                          desc: <SortDownIcon style={{ marginLeft: '5px' }} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {props.loading
              ? Common.Ui.getSkeletonTableRows(8, 7, 35)
              : table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
          </tbody>
          {!props.loading && (
            <tfoot>
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
    </>
  );
};

export default CompanyScrapersSummaryTable;
