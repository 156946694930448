import React, { useCallback, useState, useEffect } from 'react';
import Common from '../../../shared/Common';
import type { IScraper } from '../../../../interfaces/IScraper';
import ScheduleTable from './ScheduleTable';
import ManaullyWorkersPanel from './ManaullyWorkersPanel';
import type { IScraperSchedule } from '../../../../interfaces/IScraperSchedule';
import CompanyScrapersSummaryTable from './CompanyScrapersSummaryTable';
import type { IManuallyWorkerDataFeed } from '../../../../interfaces/IManuallyWorkerDataFeed';

const ProductsScrapers = () => {
  const [scrapers, setScrapers] = useState<IScraper[]>([]);
  const [loading, setLoading] = useState(true);
  const [scheduleDate, setScheduleDate] = useState<Date>(new Date());
  const [summaryScraperRow, setSummaryScraperRow] = useState<IScraper>({
    id: -1,
    name: 'Razem',
    categoryCount: 0,
    productCount: 0,
    feedCount: 0,
    averageProcessingTime: 0,
    collectedRecordsCount: 0,
    lastCompletionDate: new Date(),
  });
  const [scraperSchedules, setScraperSchedules] = useState<IScraperSchedule[]>([]);
  const [manuallyWorkerDataFeed, setManuallyWorkerDataFeed] = useState<IManuallyWorkerDataFeed[]>([]);
  const [schedulesLoading, setSchedulesLoading] = useState(true);
  const [manuallyWorkersLoading, setManuallyWorkersLoading] = useState(true);
  const [groupByCompany, setGroupByCompany] = useState(true);

  const fetchScrappersIdRef = React.useRef(0);
  const fetchManuallyWorkersIdRef = React.useRef(0);
  const scheduleFetchIdRef = React.useRef(0);

  const fetchScrappersData = useCallback(async () => {
    setLoading(true);
    const fetchId = ++fetchScrappersIdRef.current;
    const response = await Common.authorizedFetch(`api/scrapers/getScrapersData?groupByCompany=${groupByCompany}`);
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchScrappersIdRef.current) {
        setScrapers(data.result.scrapers);
      }
    }
    setLoading(false);
  }, [groupByCompany]);

  const fetchManuallyWorkersData = useCallback(async (setLoading: boolean) => {
    if (setLoading) {
      setManuallyWorkersLoading(true);
    }
    const fetchId = ++fetchManuallyWorkersIdRef.current;
    const response = await Common.authorizedFetch('api/scrapers/getManuallyWorkerData');
    const data = await response.json();
    if (data.success) {
      if (fetchId === fetchManuallyWorkersIdRef.current) {
        setManuallyWorkerDataFeed(data.result.data);
      }
    }
    if (setLoading) {
      setManuallyWorkersLoading(false);
    }
  }, []);

  const fetchScheduleData = useCallback(
    async (setLoading: boolean) => {
      if (setLoading) {
        setSchedulesLoading(true);
      }
      const fetchId = ++scheduleFetchIdRef.current;
      const response = await Common.authorizedFetch(
        `api/scrapers/getScrapersSchedule?scheduleDate=${scheduleDate.toLocaleString('en-CA').split(',')[0]}`
      );
      const data = await response.json();
      if (data.success) {
        if (fetchId === scheduleFetchIdRef.current) {
          setScraperSchedules(data.result.scrapersSchedules);
        }
      }
      if (setLoading) {
        setSchedulesLoading(false);
      }
    },
    [scheduleDate]
  );

  useEffect(() => {
    fetchScheduleData(true);

    const intervalId = setInterval(() => {
      fetchScheduleData(false);
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchScheduleData]);

  useEffect(() => {
    if (scrapers.length > 0) {
      setSummaryScraperRow({
        id: -1,
        name: 'Razem',
        categoryCount: scrapers.reduce((sum, current) => sum + current.categoryCount, 0),
        productCount: scrapers.reduce((sum, current) => sum + current.productCount, 0),
        feedCount: scrapers.reduce((sum, current) => sum + current.feedCount, 0),
        averageProcessingTime: scrapers.reduce((sum, current) => sum + current.averageProcessingTime, 0),
        collectedRecordsCount: scrapers.reduce((sum, current) => sum + current.collectedRecordsCount, 0),
        lastCompletionDate: new Date(Math.max(...scrapers.map((s) => new Date(s.lastCompletionDate).getTime()))),
      });
    }
  }, [scrapers]);

  return (
    <>
      <CompanyScrapersSummaryTable
        summaryScraperRow={summaryScraperRow}
        groupByCompany={groupByCompany}
        setGroupByCompany={setGroupByCompany}
        data={scrapers}
        loading={loading}
        fetchData={fetchScrappersData}
      />
      <ScheduleTable
        schedules={scraperSchedules}
        loading={schedulesLoading}
        scheduleDate={scheduleDate}
        setScheduleDate={setScheduleDate}
      />
      <ManaullyWorkersPanel
        data={manuallyWorkerDataFeed}
        loading={manuallyWorkersLoading}
        fetchData={fetchManuallyWorkersData}
      />
    </>
  );
};

export default ProductsScrapers;
