import { Row, Col, Form } from 'react-bootstrap';
import Common from '../../../shared/Common';
import { ClockIcon, CheckIcon, BanIcon, CircleNotchSpinIcon } from '../../../shared/icons/Svgs';
import type { IScraperSchedule } from '../../../../interfaces/IScraperSchedule';
import { type IScraperScheduleExecution, ScheduleStatus } from '../../../../interfaces/IScraperScheduleExecution';
import { useState } from 'react';

export interface IScheduleTableProps {
  schedules: IScraperSchedule[];
  loading: boolean;
  scheduleDate: Date;
  setScheduleDate: React.Dispatch<React.SetStateAction<Date>>;
}

const ScheduleTable = (props: IScheduleTableProps) => {
  const [showAllLocations, setShowAllLocations] = useState(false);

  const generateScheduleExecutionsCell = (executions: IScraperScheduleExecution[]) => (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      {executions.map((value, index) => {
        const calculateMargin = (time: Date, maxWidth: number) => {
          const minutes = time.getHours() * 60 + time.getMinutes();
          const totalMinutes = 24 * 60;
          return (minutes / totalMinutes) * maxWidth;
        };

        let isCloseToNext = true;
        if (index < executions.length - 1) {
          const nextValue = executions[index + 1];
          const diffInMinutes = Math.abs(new Date(value.time).getTime() - new Date(nextValue.time).getTime()) / 60000;
          isCloseToNext = diffInMinutes > 60;
        }

        return (
          <div style={{ position: 'relative', left: calculateMargin(new Date(value.time), 800) - index * 16 }}>
            {generateCellValueForDate(value, isCloseToNext)}
          </div>
        );
      })}
    </div>
  );

  const generateCellValueForDate = (execution: IScraperScheduleExecution, showTime: boolean) => {
    const getResultIcon = (status: ScheduleStatus) => {
      switch (status) {
        case ScheduleStatus.Scheduled:
          return <ClockIcon style={{ width: '40px', height: '40px', marginTop: '-12px', marginBottom: '-12px' }} />;
        case ScheduleStatus.Completed:
          return <CheckIcon style={{ color: 'green' }} />;
        case ScheduleStatus.CustomErrorsOccured:
        case ScheduleStatus.NoRecordsCollected:
        case ScheduleStatus.DataFeedNotFound:
          return (
            <BanIcon
              style={{ color: 'red', height: '40px', width: '40px', marginTop: '-12px', marginBottom: '-12px' }}
            />
          );
        case ScheduleStatus.Running:
          return <CircleNotchSpinIcon style={{ color: '#007bff' }} />;
      }
    };
    const getResultTitle = (execution: IScraperScheduleExecution) => {
      switch (execution.status) {
        case ScheduleStatus.Scheduled:
          return 'Zaplanowano';
        case ScheduleStatus.Completed:
          return `Przetworzono ${execution.processedRecords} rekordów`;
        case ScheduleStatus.CustomErrorsOccured:
          return 'Nieprzewidziany błąd';
        case ScheduleStatus.NoRecordsCollected:
          return 'Nie pobrano żadnych rekordów';
        case ScheduleStatus.DataFeedNotFound:
          return 'Nie znaleziono próby wykonania';
        case ScheduleStatus.Running:
          return 'Aktualnie przetwarzane';
      }
    };
    const minutes =
      new Date(execution.time).getMinutes() > 9
        ? new Date(execution.time).getMinutes()
        : '0' + new Date(execution.time).getMinutes();
    const time = `${new Date(execution.time).getHours()}:${minutes}`;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }} title={getResultTitle(execution)}>
        <Row title={time} className={`justify-content-center initialism ${showTime ? '' : 'opacity-0'}`}>
          {time}
        </Row>
        <Row className='my-1 justify-content-center'>{getResultIcon(execution.status)}</Row>
      </div>
    );
  };

  const schedules = props.schedules.reduce((acc: IScraperSchedule[], current) => {
    const elem = acc.find((item: IScraperSchedule) => item.companyId === current.companyId);
    if (showAllLocations || !elem) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  return (
    <>
      <h6 className='mt-3'>Harmonogramy wykonań</h6>
      {!props.loading && (
        <Row>
          <Col sm='auto'>
            <Form.Control
              style={{ width: 200 }}
              type='date'
              name='schedules'
              placeholder='Dzień wykonania'
              onChange={(e: any) => {
                props.setScheduleDate(new Date(e.target.value));
              }}
              defaultValue={props.scheduleDate.toLocaleString('en-CA').split(',')[0]}
            />
          </Col>
          <Col>
            <Form.Check
              className='my-2'
              type='switch'
              id='show-all-locations-switch'
              label='Pokaż wszystkie lokalizacje'
              style={{ userSelect: 'none' }}
              checked={showAllLocations}
              onChange={() => {
                setShowAllLocations((prev) => !prev);
              }}
            />
          </Col>
        </Row>
      )}
      <div className='mt-2 table-responsive'>
        <table className='table table-sm' style={{ width: '940px' }}>
          <tbody>
            {props.loading
              ? Common.Ui.getSkeletonTableRows(8, 1, 35)
              : schedules.map((value, index) => (
                  <tr key={index}>
                    <td key={-1} style={{ width: '140px' }}>
                      {value.displayName}
                    </td>
                    <td style={{ width: '800px' }}>{generateScheduleExecutionsCell(value.executions)}</td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ScheduleTable;
